import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthUser } from '../models/core.model';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CanLoadGuard implements CanActivate {
  user: AuthUser;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}
 
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    let loggedIn = false;
    this.authService.getLoginState()
    .subscribe(data => {
      if(data && data.userId) {
        loggedIn = true;
      }
    }).unsubscribe();

    if (loggedIn) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
} 