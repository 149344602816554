<div class="login-container">
  <ng-container>
    <div class="login-process">
      <div class="login-title">LOGIN</div>
      <div class="welcome-text mt-28">
        WELCOME BACK!
      </div>
      <div class="create-account-container">
        <div class="welcome-text">NOT REGISTERED YET?</div>
        <div class="no-selection ml-8 create-account-btn whiggle" (click)="goToSignup()">CREATE AN ACCOUNT</div>
      </div>
      <div class="error" *ngIf="loginError">
        {{loginError}}
      </div>
      <form [formGroup]="loginForm" class="mt-4 login-form" (keyup.enter)="submitLoginForm()">
        <div>
          <mat-form-field appearance="outline" class="w-100-p m-0 melt-common-mat-field">
            <input
              formControlName="userEmail"
              matInput
              placeholder="EMAIL"
              required
              type="email"
            />
            <div *ngIf="userEmail.invalid && loginFormError" class="melt-common-field-error">
              Please enter a valid email
            </div>
          </mat-form-field>
        </div>
        <div class="">
          <mat-form-field appearance="outline" class="w-100-p m-0 melt-common-mat-field mat-no-border-top">
            <input
              formControlName="password"
              matInput
              placeholder="PASSWORD"
              required
              type="password"
            />
            <div *ngIf="password.invalid && loginFormError" class="melt-common-field-error">
              Please enter password
            </div>
          </mat-form-field>
        </div>
      </form>
      <div class="welcome-text mt-32 cursor-pointer outline-none" [routerLink]="['/changePassword']">
        FORGOT PASSWORD?
      </div>
      <button class="theme-btn enter-btn mt-28" (click)="submitLoginForm()">
        ENTER
      </button>
      
    </div>

  </ng-container>
  
</div>