import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'melt-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetLinkForm: FormGroup;
  resetLinkFormError = false;
  resetPassForm: FormGroup;
  resetPassFormError = false;
  notificationMsg = '';
  showResetLinkForm = true;
  resetPassEmail = '';
  resetPassToken = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private coreService: CoreService,
  ) { }

  ngOnInit(): void {
    this.resetPassEmail = this.route.snapshot.queryParams['e'];
    this.resetPassToken = this.route.snapshot.queryParams['t'];

    if(this.resetPassEmail && this.resetPassToken) {
      this.showResetLinkForm = false;
    } else {
      this.showResetLinkForm = true;
    }

    this.resetLinkForm = new FormGroup({
      userEmail: new FormControl('', [
        Validators.required,
        Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')
      ])
    });

    this.resetPassForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
      ]),
    }, { validators: this.controlsEqual('confirmPassword', 'password') });
  }

  get userEmail() {
    return this.resetLinkForm.get('userEmail');
  }

  get resetLinkForm_invalid() {
    return this.resetLinkForm.invalid;
  }

  get password() {
    return this.resetPassForm.get('password');
  }

  get confirmPassword() {
    return this.resetPassForm.get('confirmPassword');
  }

  get resetPassForm_invalid() {
    return this.resetPassForm.invalid;
  }

  submitResetLinkForm() {
    const resetData = this.resetLinkForm.getRawValue();
    if(this.resetLinkForm.valid) {
      this.coreService.sendPassResetLink(resetData.userEmail)
      .subscribe(res => {
        if(res && res.success) {
          this.notificationMsg = 'CHECK YOUR EMAIL TO RESET YOUR PASSWORD';
        } else {
          this.notificationMsg = 'Please enter a registered email address';
        }
        this.resetLinkFormError = false;
      });
    } else {
      this.resetLinkFormError = true;
    }
  }

  submitResetPasswordForm() {
    const resetPassData = this.resetPassForm.getRawValue();
    if(this.resetPassForm.valid) {
      const _hash = Md5.hashStr(resetPassData.password) as any;
      this.coreService.resetPassword(this.resetPassEmail, this.resetPassToken, _hash)
      .subscribe(res => {
        if(res && res.success) {
          this.notificationMsg = '';
          this.router.navigate(['/'], { replaceUrl: true });
        } else {
          this.notificationMsg = 'SOME ERROR OCCURED. MAKE SURE YOUR LINK IS CORRECT';
        }
        this.resetPassFormError = false;
      });
    } else {
      this.resetPassFormError = true;
    }
  }

  private controlsEqual(
    controlName: string,
    equalToName: string,
    errorKey: string = controlName
  ) {
    return (form: FormGroup) => {
      const control = form.get(controlName);

      if (control.value !== form.get(equalToName).value) {
        control.setErrors({ [errorKey]: true });
        return {
          [errorKey]: true
        }
      } else {
        control.setErrors(null);
        return null
      }
    }
  }

}
