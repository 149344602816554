import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'melt-cookies-banner',
  templateUrl: './cookies-banner.component.html',
  styleUrls: ['./cookies-banner.component.scss']
})
export class CookiesBannerComponent implements OnInit {

  showCookiePopup = true;
  isTablet = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 600px)'])
      .subscribe((result) => {
        this.isTablet = this.breakpointObserver.isMatched(
          '(max-width: 600px)'
        );
      });
  }

  acceptCookies() {
    localStorage.setItem('melt_accept_cookies', 'true');
    this.closeCookiePopup();
  }

  closeCookiePopup() {
    this.showCookiePopup = false;
  }
}
