// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environmentProd = {
  production: false,
  baseUrl: 'https://api.weareallmelthere.com/api/',
  assetsUrl: 'https://assets.weareallmelthere.com',
  meltUrl: 'https://weareallmelthere.com/',
};

export const environmentTest = {
  production: false,
  baseUrl: 'https://api.weareallmelthere.com/api/',
  assetsUrl: 'https://assets.weareallmelthere.com',
  meltUrl: 'https://test.weareallmelthere.com/',
};

export const environment = environmentTest;

