<div class="signup-container">
  <ng-container>
    <div class="signup-process">
      <div class="signup-title">JOIN THE MELT COMMUNITY!</div>
      <div class="welcome-text">
        REGISTER TO RECEIVE 10% OFF ON YOUR FIRST PURCHASE!
      </div>
      <!-- <div class="error">
        INCORRECT EMAIL OR PASSWORD
      </div> -->
      <form [formGroup]="signupForm" class="mt-40 signup-form" (keyup.enter)="submit()">
        <div class="two-inputs">
          <mat-form-field appearance="outline" class="w-100-p m-0 melt-common-mat-field">
            <input
              formControlName="firstName"
              matInput
              placeholder="FIRST NAME"
              required
              type="text"
            />
            <div *ngIf="firstName.invalid && signupFormError" class="melt-common-field-error">
              Enter only alphabets(min 3)
            </div>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100-p m-0 melt-common-mat-field mat-no-border-left">
            <input
              formControlName="lastName"
              matInput
              placeholder="LAST NAME"
              required
              type="text"
            />
            <div *ngIf="lastName.invalid && signupFormError" class="melt-common-field-error">
              Enter only alphabets(min 3)
            </div>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="w-100-p m-0 melt-common-mat-field mat-no-border-top">
            <input
              formControlName="userEmail"
              matInput
              placeholder="EMAIL"
              required
              type="email"
            />
            <div *ngIf="userEmail.invalid && signupFormError" class="melt-common-field-error">
              Please enter a valid email
            </div>
          </mat-form-field>
        </div>
        <div class="two-inputs">
          <mat-form-field appearance="outline" class="w-100-p m-0 melt-common-mat-field mat-no-border-top">
            <input
              formControlName="password"
              matInput
              placeholder="PASSWORD"
              required
              type="password"
            />
            <div *ngIf="password.invalid && signupFormError" class="melt-common-field-error">
              Enter min 8 characters
            </div>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100-p m-0 melt-common-mat-field mat-no-border-left mat-no-border-top">
            <input
              formControlName="confirmPassword"
              matInput
              placeholder="CONFIRM PASSWORD"
              required
              type="password"
            />
            <div *ngIf="confirmPassword.invalid && signupFormError" class="melt-common-field-error">
              Password doesn't match
            </div>
          </mat-form-field>
        </div>
      </form>
      <div class="error mt-56" *ngIf="customError">
        {{customError}}
      </div>
      <button class="theme-btn enter-btn mt-20" (click)="submit()">
        CREATE
      </button>
    </div>

  </ng-container>
  
</div>