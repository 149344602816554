import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  _authObserver: Subscription;
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(childRoute, state);
  }

  checkLogin(url: string): boolean {
    let loggedIn = false;

    this.authService.getLoginState()
    .subscribe(data => {
      if(data && data.userId) {
        loggedIn = true;
      }
    })

    if (loggedIn) {
      return true;
    }

    this.router.navigate(['/login'], {
      queryParams: { redirect: url },
    });
    return false;
  }
}
