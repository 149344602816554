import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { CoreService } from '../../services/core.service';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'melt-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  signupFormError = false;
  customError = '';
  redirect: string;
  redirectQParams: Params;

  constructor(
    private coreService: CoreService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.redirect = this.route.snapshot.queryParams['redirect'];

    this.signupForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^[a-zA-Z ]*$')
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^[a-zA-Z ]*$')
      ]),
      userEmail: new FormControl('', [
        Validators.required,
        Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
      ]),
    }, { validators: this.controlsEqual('confirmPassword', 'password') });
  }

  get firstName() {
    return this.signupForm.get('firstName');
  }

  get lastName() {
    return this.signupForm.get('lastName');
  }

  get userEmail() {
    return this.signupForm.get('userEmail');
  }

  get password() {
    return this.signupForm.get('password');
  }

  get confirmPassword() {
    return this.signupForm.get('confirmPassword');
  }

  get form_invalid() {
    return this.signupForm.invalid;
  }

  submit() {
    const signupData = this.signupForm.getRawValue();
    if(this.signupForm.valid) {
      const _hash = Md5.hashStr(signupData.password) as any;
      this.coreService.signup(signupData.firstName.trim(), signupData.lastName.trim(), signupData.userEmail, _hash)
      .subscribe(res => {
        if(res && res.success && res.data?.userId) {
          this.customError = '';
          this.authService.setLoginState(res.data);
          this.redirectUser();
        } else if(res?.message) {
          this.customError = res.message;
        } else {
          this.customError = 'SOMETHING WENT WRONG. TRY AGAIN';
        }
        this.signupFormError = false;
      })
    } else {
      this.signupFormError = true;
    }
  }

  redirectUser() {
    if (this.redirect) {
      const redirectURL = this.router.parseUrl(this.redirect);
      this.redirectQParams = redirectURL.queryParams;
      const primaryChildren = redirectURL.root.children['primary'];
      if (primaryChildren) {
        this.redirect = primaryChildren.segments
          .map((it) => it.path)
          .join('/'); // Redirect url without query params
      }

      this.router.navigate([this.redirect], {
        queryParams: this.redirectQParams,
      });
    } else {
      this.router.navigate(['/'], { replaceUrl: true });
    }
  }

  private controlsEqual(
    controlName: string,
    equalToName: string,
    errorKey: string = controlName
  ) {
    return (form: FormGroup) => {
      const control = form.get(controlName);

      if (control.value !== form.get(equalToName).value) {
        control.setErrors({ [errorKey]: true });
        return {
          [errorKey]: true
        }
      } else {
        control.setErrors(null);
        return null
      }
    }
  }
}