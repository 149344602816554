export const URLS = {
  V1: {
    ACCOUNT: {
      LOGIN: 'v1.0/login',
      LOGIN_STATUS: 'v1.0/webindex',
      SIGNUP: 'v1.0/signup',
      LOGOUT: 'v1.0/logout',
      SEND_PASS_RESET_LINK: 'v1.0/forgot-password/send-link',      
      RESET_PASSWORD: 'v1.0/forgot-password/password-reset',   
      ADD_ADDRESS: 'v1.0/profile/add-address',
      UPDATE_ADDRESS: 'v1.0/profile/update-address',
      DELETE_ADDRESS: 'v1.0/profile/delete-address',
      SUBSCRIBE: 'v1.0/subscribe',
      CHECK_USER: 'v1.0/check-user',
      ACTIVATE_EMAIL: 'v1.0/activate-email'
    },
    PRODUCT: {
      FETCH_PRODUCTS: 'v1.0/strapi/products/get-products/',
    },
    CRYSTALS: {
      FETCH_CRYSTALS: 'v1.0/strapi/crystals/get-crystals/'
    },
    CUSTOM_SHOP: {
      FETCH_HEADS: 'v1.0/strapi/heads/get-heads/',
      FETCH_CRYSTALS: 'v1.0/strapi/custom-crystals/get-custom-crystals/',
      FETCH_COLORS: 'v1.0/strapi/colors/get-colors/',
      FETCH_COMBOS: 'v1.0/strapi/combos/get-combos/',
    },
    CART: {
      ADD_ITEM: 'v1.0/cart/add-to-cart',
      DELETE_ITEM: 'v1.0/cart/remove-from-cart',
      FETCH_CART: 'v1.0/cart/get-cart',
      PRE_CHECKOUT: 'v1.0/cart/pre-checkout',
      CHECKOUT: 'v1.0/cart/checkout',
      FETCH_ORDERS: 'v1.0/cart/get-orders',
    },
    VOUCHER: {
      FETCH_VOUCHER: 'v1.0/voucher/get-voucher'
    }
  },
  V2: {}
};
