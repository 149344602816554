<ng-container *ngIf="!isTablet; else showTabletView">
  <div class="cookies-container" *ngIf="showCookiePopup">
    <div class="inner-container">
      <img src="assets/icons/cross-black.png" alt="" class="cross-icon c-p no-selection" (click)="closeCookiePopup()">
      <div class="title">
        BY CONTINUING TO USE THE SITE, <br>
        YOU AGREE TO THE USE OF COOKIES
      </div>
      <div class="accept-btn c-p no-selection" (click)="acceptCookies()">
        ACCEPT
      </div>
      <div class="info-btn c-p no-selection" [routerLink]="['/info/privacy-policy']">
        MORE INFO
      </div>
    </div>
  </div>
</ng-container>
<ng-template #showTabletView>
  <div class="cookies-container" *ngIf="showCookiePopup">
    <div class="inner-container">
      <img src="assets/icons/cross-black.png" alt="" class="cross-icon c-p no-selection" (click)="closeCookiePopup()">
      <div class="title-container">
        <div class="title">
          BY CONTINUING TO USE THE SITE, YOU AGREE TO THE USE OF COOKIES.
        </div>
        <div class="info-btn c-p no-selection" [routerLink]="['/info/privacy-policy']">
          MORE INFO
        </div>
      </div>
      <div class="accept-btn c-p no-selection" (click)="acceptCookies()">
        ACCEPT
      </div>
    </div>
  </div>
</ng-template>