import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LandingComponent } from './components/landing/landing.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { EmailVerifiedComponent } from './components/email-verified/email-verified.component';
import { CookiesBannerComponent } from './components/cookies-banner/cookies-banner.component';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  declarations: [HeaderComponent,
    FooterComponent,
    LandingComponent, 
    NotFoundComponent, 
    LoginComponent, 
    SignupComponent, 
    ResetPasswordComponent, EmailVerifiedComponent, CookiesBannerComponent
  ],
})
export class CoreModule { }
