import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanLoadGuard } from './core/guards/can-load.guard';
import { LandingComponent } from './core/components/landing/landing.component';
import { LoginComponent } from './core/components/login/login.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { SignupComponent } from './core/components/signup/signup.component';
import { ResetPasswordComponent } from './core/components/reset-password/reset-password.component';
import { AuthGuard } from './core/guards/auth.guard';
import { EmailVerifiedComponent } from './core/components/email-verified/email-verified.component';


const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./home/home.module').then(
            (m) => m.HomeModule
          ),
      },
      {
        path: 'login',
        canActivate: [CanLoadGuard],
        component: LoginComponent
      },
      {
        path: 'changePassword',
        canActivate: [CanLoadGuard],
        component: ResetPasswordComponent
      },
      {
        path: 'emailVerified',
        component: EmailVerifiedComponent
      },
      {
        path: 'signup',
        canActivate: [CanLoadGuard],
        component: SignupComponent
      },
      { 
        path: 'products',
        loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
      },
      { 
        path: 'crystals',
        loadChildren: () => import('./crystals/crystals.module').then(m => m.CrystalsModule) 
      },
      {
        path: 'account',
        canActivate: [AuthGuard],
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
      },
      { 
        path: 'custom-shop', 
        loadChildren: () => import('./custom-shop/custom-shop.module').then(m => m.CustomShopModule) 
      },
      { 
        path: 'info', 
        loadChildren: () => import('./info/info.module').then(m => m.InfoModule) 
      },
      {
        path: 'cart', 
        loadChildren: () => import('./cart/cart.module').then(m => m.CartModule) 
      },
      { 
        path: 'fourofour', 
        component: NotFoundComponent 
      }
    ]
  },
  { path: '**', redirectTo: '/fourofour' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    initialNavigation: 'disabled',
    // onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
