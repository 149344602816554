<div class="404-section-container">
  <div class="not-found-container">
    <img src="assets/images/404.jpg" alt="404 not found" class="not-found-img">
    <div class="not-found-text">
      Looks like you've reached infinity...
    </div>
    <button class="mt-32 theme-btn" routerLink="/">
      Fly Back Home
    </button>
  </div>
</div>