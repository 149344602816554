import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { AuthUser } from '../../models/core.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'melt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  CATEGORIES = [
    // 'THE CUSTOM SHOP',
    'RINGS',
    'PENDANTS',
    'EARRINGS',
    'CHAINS',  
  ]

  user: AuthUser;
  showProductsList = false;
  cartItemCount: number = 0;
  isTablet = false;
  showSidebar = false;
  showSidebarJewelOptions = false;
  searchText = '';
  prevSearchText = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private sharedService: SharedService,
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 960px)'])
      .subscribe((result) => {
        this.isTablet = this.breakpointObserver.isMatched(
          '(max-width: 960px)'
        );
      });

    this.authService.getLoginState().subscribe(user => {
      this.user = user;

      if(user?.userId === null) {
        this.cartItemCount = 0;
      }
    });

    this.sharedService.getCartItemState().subscribe(count => {
      this.cartItemCount = count;
    });
  }

  ngAfterViewInit() {
    this.setSidebarHeight();
  }

  changeSidebar(option) {
    this.showSidebarJewelOptions = false;
    this.setSidebarHeight();
    if(option === 'open') {
      this.showSidebar = true;
    }
    if(option === 'close') {
      this.showSidebar = false;
    }
  }

  openProducts(category = 'ALL') {
    if(category === 'THE CUSTOM SHOP') {
      this.router.navigate(['/custom-shop']);
    } else {
      this.router.navigate(['/products'], {
        queryParams: { selCategory: category}
      });
    }

    this.showProductsList = false;
  }

  setSidebarHeight() {
    setTimeout(() => {
      const landingAppElem = document.getElementById('landing-app');
      let rootElem = document.querySelector(':root') as HTMLElement;
      if(landingAppElem?.scrollHeight && rootElem?.style) {
        rootElem.style.setProperty('--right-sidebar-height', `calc(${landingAppElem.scrollHeight}px - 14.493vw)`);
      }
    }, 1000);
  }

  searchQuery() {
    if(this.searchText !== this.prevSearchText) {
      this.prevSearchText = this.searchText;
      this.router.navigate(['/products'], {
        queryParams: { search: this.searchText }
      });
    }
  }
}
