import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private base_url = environment.baseUrl;
  constructor(private http: HttpClient) { }

  get<T>(url, params = {}) {
    return this.http.get<T>(`${this.base_url}${url}`, {
      params,
      observe: 'body',
      responseType: 'json',
    });
  }
  
  post<T>(url, data: any = null, params = {}) {
    return this.http.post<T>(`${this.base_url}${url}`, data, {
      params,
      observe: 'body',
      responseType: 'json',
    });
  }

  put<T>(url, data: any = null, params = {}) {
    return this.http.put<T>(`${this.base_url}${url}`, data, {
      params,
      observe: 'body',
      responseType: 'json',
    });
  }

  patch<T>(url, data: any = null, params = {}) {
    return this.http.patch<T>(`${this.base_url}${url}`, data, {
      params,
      observe: 'body',
      responseType: 'json',
    });
  }

  delete<T>(url, data: any = null, params = {}) {
    return this.http.delete<T>(`${this.base_url}${url}`, {
      params,
      observe: 'body',
      responseType: 'json',
    });
  }
}
