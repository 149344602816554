import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'melt-email-verified',
  templateUrl: './email-verified.component.html',
  styleUrls: ['./email-verified.component.scss']
})
export class EmailVerifiedComponent implements OnInit {
  verifiedText = ''

  constructor(
    private route: ActivatedRoute,
    private coreService: CoreService
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.pipe().subscribe((res) => {
      this.activateEmail(res['params']);
    });
  }

  activateEmail(params) {
    if(params?.e && params?.t) {
      this.coreService.activateEmail(params.e, params.t)
      .subscribe(res => {
        if(res.success) {
          this.verifiedText = 'Congrats, Your email has been verified!';
        } else {
          this.verifiedText = 'Sorry, your email has not been verified!';
        }
      }, error => {
        this.verifiedText = 'Some error occured, try again later.'
      })
    }
  }

}
