import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { Crystal } from '../../crystals/models/crystals.model';
import { HttpService } from '../../networking/http.service';
import { Resource } from '../../networking/resource';
import { URLS } from '../../networking/urls';
import { Product } from '../../products/models/products.model';

@Injectable({
  providedIn: 'root'
})
export class SharedApiService {

  constructor(
    private httpService: HttpService,
    private authService: AuthService
  ) { }

  fetchProducts(params: { 
    category?: string;
    'color.name'?: string;
    name?: string;
    'crystal.name'?: string;
    topProduct?: boolean;
    _sort?: string;
    _start?: number;
    _limit?: number;
    _q?: string;
  }): Observable<Resource<Product[]>> {
    return this.httpService.get<Resource<Product[]>>(URLS.V1.PRODUCT.FETCH_PRODUCTS, params);
  }

  fetchSingleProduct(id: string): Observable<Resource<Product>> {
    return this.httpService.get<Resource<Product>>(`${URLS.V1.PRODUCT.FETCH_PRODUCTS}${id}`);
  }

  fetchCrystals(): Observable<Resource<Crystal[]>> {
    return this.httpService.get<Resource<Crystal[]>>(URLS.V1.CRYSTALS.FETCH_CRYSTALS);
  }

  addAddress(
    address: {
      flat: string;
      street: string;
      postcode: string;
      city: string;
      country: string;
      code: string;
      mobile: string;
    },
    setDefault: boolean
  ): Observable<Resource<any>> {
    const body = {
      address,
      setDefault
    }
    return this.httpService.post<Resource<any>>(URLS.V1.ACCOUNT.ADD_ADDRESS, body);
  }

  updateAddress(
    address: {
      flat: string;
      street: string;
      postcode: string;
      city: string;
      country: string;
      code: string;
      mobile: string;
    },
    index: number,
    setDefault: boolean
  ): Observable<Resource<any>> {
    const body = {
      address,
      index,
      setDefault
    }
    return this.httpService.post<Resource<any>>(URLS.V1.ACCOUNT.UPDATE_ADDRESS, body);
  }

  deleteAddress(
    index: number
  ): Observable<Resource<any>> {
    const body = {
      index
    }
    return this.httpService.post<Resource<any>>(URLS.V1.ACCOUNT.DELETE_ADDRESS, body);
  }

  fetchCustomHeads() {
    return this.httpService.get<Resource<Crystal[]>>(URLS.V1.CUSTOM_SHOP.FETCH_HEADS);
  }

  fetchSingleCustomHead(id: string) {
    return this.httpService.get<Resource<Crystal[]>>(URLS.V1.CUSTOM_SHOP.FETCH_HEADS + id);
  }

  fetchCustomCrystals() {
    return this.httpService.get<Resource<Crystal[]>>(URLS.V1.CUSTOM_SHOP.FETCH_CRYSTALS);
  }

  fetchSingleCustomCrystal(id: string) {
    return this.httpService.get<Resource<Crystal[]>>(URLS.V1.CUSTOM_SHOP.FETCH_CRYSTALS + id);
  }

  fetchCustomCombos(
    customCrystalId: string,
    headId: string
  ) {
    const params = {
      customCrystal: customCrystalId,
      head: headId
    }
    return this.httpService.get<Resource<any>>(URLS.V1.CUSTOM_SHOP.FETCH_COMBOS, params);
  }

  fetchColors() {
    return this.httpService.get<Resource<Crystal[]>>(URLS.V1.CUSTOM_SHOP.FETCH_COLORS);
  }

  addToCart(data: {
    productId?: string;
    sizeId?: string;
    headId?: string;
    crystalId?: string;
    guestId?: string;
  }): Observable<Resource<any>> {
    const guestUUID = this.authService.getGuestUser();
    if(guestUUID) {
      data.guestId = guestUUID;
    }

    const body = {
      ...data
    }
    return this.httpService.post<Resource<any>>(URLS.V1.CART.ADD_ITEM, body);
  }

  deleteFromCart(data: {
    cartProductId?: string;
    guestId?: string;
  }): Observable<Resource<any>> {
    const guestUUID = this.authService.getGuestUser();
    if(guestUUID) {
      data.guestId = guestUUID;
    }

    const body = {
      ...data
    }
    return this.httpService.post<Resource<any>>(URLS.V1.CART.DELETE_ITEM, body);
  }

  fetchCart(data: {
    guestId?: string;
  } = {}): Observable<Resource<any>> {
    const guestUUID = this.authService.getGuestUser();
    if(guestUUID) {
      data.guestId = guestUUID;
    }

    const body = {
      ...data
    }
    return this.httpService.post<Resource<any>>(URLS.V1.CART.FETCH_CART, body);
  }

  preCheckout(data: {
    guestId?: string;
    shipping?: string;
    voucher?: string;
    email?: string;
  } = {}): Observable<Resource<any>> {
    const guestUUID = this.authService.getGuestUser();
    if(guestUUID) {
      data.guestId = guestUUID;
    }

    const body = {
      ...data
    }
    return this.httpService.post<Resource<any>>(URLS.V1.CART.PRE_CHECKOUT, body);
  }

  fetchOrders(): Observable<Resource<any>> {
    return this.httpService.get<Resource<any>>(URLS.V1.CART.FETCH_ORDERS);
  }

  checkUser(
    email: string
  ): Observable<Resource<any>> {
    const body = {
      email
    }
    return this.httpService.post<Resource<any>>(URLS.V1.ACCOUNT.CHECK_USER, body);
  }
}
