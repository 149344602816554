import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare let gtag:Function;
declare let fbq:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private router: Router) {
    router.initialNavigation();

    this.router.events.subscribe((e: any) => {
      if(e instanceof NavigationEnd) {
        console.log(e.urlAfterRedirects, 'routeActivated');
        gtag('config', 'G-8T0GHFFB6W', { 'page_path': e.urlAfterRedirects });

        // if(!e.urlAfterRedirects.match('/products/+')) {
          fbq('track', 'PageView');
          fbq('track','ViewContent');
        // }
      }
    });

    // router.events.subscribe((y: NavigationEnd) => {
    //     if(y instanceof NavigationEnd){
    //       fbq('track', 'PageView');
    //     }
    // })
  }
}
