import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../services/core.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'melt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  email = '';
  error = '';
  isTablet = false;
  currentYear = null;

  constructor(
    private coreService: CoreService,
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(max-width: 960px)'])
      .subscribe((result) => {
        this.isTablet = this.breakpointObserver.isMatched(
          '(max-width: 960px)'
        );
      });

    this.setCurrentYear();
  }

  private setCurrentYear() {
    const currDate = new Date();
    this.currentYear = currDate.getFullYear();
  }

  addEmail() {
    const email = this.email.trim()
    if(email && this.validateEmail(email)) {
      this.email = '';
      this.coreService.subscribeEmail(email).subscribe(res => {
        if(res && res.success) {
          this.error = 'Email subscribed!'
        } else {
          if(res?.message === 'Try again, or use another email') {
            this.error = 'This email is already registered!'
          } else {
            this.error = 'Please enter a valid email'
          }
        }
      })
    } else {
      this.error = 'Please enter a valid email'
    }
  }

  private validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
