<div class="reset-pass-container">
  <ng-container *ngIf="showResetLinkForm">
    <div class="login-process">
      <div class="login-title">RESET YOUR PASSWORD</div>
      <div class="welcome-text">
        WE WILL SEND YOU AN EMAIL TO COMPLETE THE PASSWORD RESET
      </div>
      <form [formGroup]="resetLinkForm" class="mt-32 login-form" (keyup.enter)="submitResetLinkForm()">
        <div>
          <mat-form-field appearance="outline" class="w-100-p m-0 melt-common-mat-field">
            <input
              formControlName="userEmail"
              matInput
              placeholder="EMAIL"
              required
              type="email"
            />
            <div *ngIf="userEmail.invalid && resetLinkFormError" class="melt-common-field-error">
              Please enter a valid email
            </div>
          </mat-form-field>
        </div>
      </form>
      <div class="error mt-16" *ngIf="notificationMsg">
        {{notificationMsg}}
      </div>
      <div class="buttons-container mt-60">
        <button class="theme-btn enter-btn" (click)="submitResetLinkForm()">
          SUBMIT
        </button>
        <button class="theme-btn cancel-btn" [routerLink]="['/login']">
          CANCEL
        </button>
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="!showResetLinkForm">
    <div class="login-process">
      <div class="login-title account-pass">RESET ACCOUNT PASSWORD</div>
      <div class="welcome-text">
        ENTER THE NEW PASSWORD
      </div>
      <form [formGroup]="resetPassForm" class="mt-32 login-form" (keyup.enter)="submitResetPasswordForm()">
        <div>
          <mat-form-field appearance="outline" class="w-100-p m-0 melt-common-mat-field">
            <input
              formControlName="password"
              matInput
              placeholder="PASSWORD"
              required
              type="password"
            />
            <div *ngIf="password.invalid && resetPassFormError" class="melt-common-field-error">
              Enter min 8 characters
            </div>
          </mat-form-field>
          <mat-form-field appearance="outline" class="w-100-p m-0 melt-common-mat-field mat-no-border-top">
            <input
              formControlName="confirmPassword"
              matInput
              placeholder="CONFIRM PASSWORD"
              required
              type="password"
            />
            <div *ngIf="confirmPassword.invalid && resetPassFormError" class="melt-common-field-error">
              Password doesn't match
            </div>
          </mat-form-field>
        </div>
      </form>
      <div class="error mt-16" *ngIf="notificationMsg">
        {{notificationMsg}}
      </div>
      <div class="buttons-container mt-60">
        <button class="theme-btn" (click)="submitResetPasswordForm()">
          UPDATE
        </button>
      </div>
    </div>

  </ng-container>
  
</div>