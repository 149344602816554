import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthUser } from '../models/core.model';
import { CoreService } from './core.service';
import * as uuid from 'uuid';

@Injectable()
export class AuthService {
  private loginData$: BehaviorSubject<AuthUser> = new BehaviorSubject<AuthUser>({
    userId: null,
    userEmail: null,
    sessionId: null
  });

  constructor(
    private coreService: CoreService,
    private router: Router
  ) {}

  load(): Promise<any> {
    // to initialize the app with login_status response(to set Auth State)
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 2500); // resolving after 2.5 seconds if there is an api crash or speed deficiency

      this.coreService.loginStatus().subscribe(res => {
        if(res && res.data?.userId) {
          this.setLoginState(res.data);
          resolve(true);
        } else {
          // set default state if required when not logged in
          this.removeAuthToken();
          this.setGuestUser();
          resolve(true);
        }
      })
    })
  }

  setLoginState(state: AuthUser) {
    this.loginData$.next(state);
    this.setAuthToken(state?.sessionId);
    this.removeGuestUser();
  }

  getLoginState(): Observable<AuthUser> {
    return this.loginData$.pipe(
      map((state) => {
        return state;
      })
    );
  }

  setAuthToken(token: string) {
    localStorage.setItem('melt_auth_token', token);
  }

  getAuthToken() {
    let token = localStorage.getItem('melt_auth_token');
    return token || null;
  }

  removeAuthToken() {
    localStorage.removeItem('melt_auth_token');
  }

  checkWebIndex() {
    this.coreService.loginStatus().subscribe(res => {
      if(res && res.data?.userId) {
        this.setLoginState(res.data);
      } else {
        // set default state if required when not logged in
        this.loginData$.next({
          userId: null,
          userEmail: null,
          sessionId: null
        });
      }
    })
  }

  logoutUser() {
    this.coreService.logout().subscribe(res => {
      if(res && res.success) {
        this.removeAuthToken();
        this.setGuestUser();
        this.loginData$.next({
          userId: null,
          userEmail: null,
          sessionId: null
        });
        this.router.navigate(['/']);
      } else {
        // show error @todo;
      }
    })
  }

  private setGuestUser() {
    if(!this.getGuestUser()) {
      const guestUUID = uuid.v4();
      localStorage.setItem('melt_guest_id', guestUUID);
    }
  }

  removeGuestUser() {
    localStorage.removeItem('melt_guest_id');
  }

  getGuestUser() {
    const guestUUID = localStorage.getItem('melt_guest_id');
    return guestUUID || null;
  }
}
