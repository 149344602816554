import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'melt-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  showCookiePopup = false;

  constructor() { }

  ngOnInit() {
    this.setCookiePopup();
  }

  private setCookiePopup() {
    setTimeout(() => {
      if(!localStorage.getItem('melt_accept_cookies')) {
        this.showCookiePopup = true;
      }
    }, 2000);
  }

}
