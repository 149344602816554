import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { CoreService } from '../../services/core.service';
import { AuthService } from '../../services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'melt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginFormError = false;
  redirect: string;
  redirectQParams: Params;
  loginError: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private coreService: CoreService,
    private sharedService: SharedService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.redirect = this.route.snapshot.queryParams['redirect'];

    this.loginForm = new FormGroup({
      userEmail: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', [
        Validators.required
      ]),
    });
  }

  get userEmail() {
    return this.loginForm.get('userEmail');
  }

  get password() {
    return this.loginForm.get('password');
  }

  get form_invalid() {
    return this.loginForm.invalid;
  }

  submitLoginForm() {
    const loginFormData = this.loginForm.getRawValue();
    if(this.loginForm.valid) {
      const _hash = Md5.hashStr(loginFormData.password) as any;
      this.coreService.login(loginFormData.userEmail, _hash)
      .subscribe(res => {
        if(res && res.data?.userId) {
          this.loginError = '';
          this.authService.setLoginState(res.data);
          this.sharedService.fetchCart();
          this.redirectUser();
        } else {
          this.loginError = 'INCORRECT EMAIL OR PASSWORD';
        }
        this.loginFormError = false;
      });
    } else {
      this.loginFormError = true;
    }
  }

  redirectUser() {
    if (this.redirect) {
      const redirectURL = this.router.parseUrl(this.redirect);
      this.redirectQParams = redirectURL.queryParams;
      const primaryChildren = redirectURL.root.children['primary'];
      if (primaryChildren) {
        this.redirect = primaryChildren.segments
          .map((it) => it.path)
          .join('/'); // Redirect url without query params
      }

      this.router.navigate([this.redirect], {
        queryParams: this.redirectQParams,
      });
    } else {
      this.router.navigate(['/'], { replaceUrl: true });
    }
  }

  goToSignup() {
    this.router.navigate(['/signup'], {
      queryParams: { redirect: this.redirect },
    });
  }
}
