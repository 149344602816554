import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDataLS } from 'src/app/cart/models/cart.model';
import { SharedApiService } from './shared-api.service';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private cartItemCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private mobileShowAccOption$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private selectedShipping$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private guestEmailChange$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private sharedApiService: SharedApiService,
    private meta: Meta
  ) {
    this.initService();
  }

  private initService() {
    this.fetchCart();
  }

  fetchCart() {
    this.sharedApiService.fetchCart()
    .subscribe(res => {
      if(res?.success && res?.data) {
        this.setCartItemState(res.data.products?.length);
      }
    })
  }

  setCartItemState(state) {
    this.cartItemCount$.next(state);
  }

  getCartItemState(): Observable<number> {
    return this.cartItemCount$.pipe(
      map((state) => {
        return state;
      })
    );
  }

  setMobileShowAccOption(state) {
    this.mobileShowAccOption$.next(state);
  }

  getMobileShowAccOption(): Observable<boolean> {
    return this.mobileShowAccOption$.pipe(
      map((state) => {
        return state;
      })
    );
  }

  setSelectedShipping(state) {
    this.selectedShipping$.next(state);
  }

  getSelectedShipping(): Observable<any> {
    return this.selectedShipping$.pipe(
      map((state) => {
        return state;
      })
    );
  }

  setGuestEmailChange(state) {
    this.guestEmailChange$.next(state);
  }

  getGuestEmailChange(): Observable<any> {
    return this.guestEmailChange$.pipe(
      map((state) => {
        return state;
      })
    );
  }

  addUserDetailsToLocalStorage(key, value) {
    /**
     * userData : {
     *  'userType': string['user', 'guest']
     *  'email': string
     *  'shipDetails': {}
     *  'guestSubscribe': boolean
     *  'shipAddressType': string['address', 'guest']
     *  'addressIndex': 0 or 1, for saved user addresses if selected for payment
     *  'voucher': string
     * }
     */
    let userData: UserDataLS = JSON.parse(localStorage.getItem('melt_user_details'));
    let newUserData: any = {};
    if(userData && Object.keys(userData).length > 0) {
      newUserData = {...userData}
    }
    newUserData[key] = value;
    localStorage.setItem('melt_user_details', JSON.stringify(newUserData));
  }

  fetchUserDetailsFromLS() {
    return JSON.parse(localStorage.getItem('melt_user_details'));
  }

  addPixelCatalog(seo) {
    this.meta.addTags([
      { property: 'og:title', content: seo.name },
      { property: 'og:description', content: seo.description },
      { property: 'og:url', content: seo.url },
      { property: 'og:image', content: seo.image },
      { property: 'product:brand', content: seo.brand },
      { property: 'product:availability', content: seo.availability },
      { property: 'product:condition', content: seo.itemCondition },
      { property: 'product:price:amount', content: seo.price },
      { property: 'product:price:currency', content: seo.priceCurrency },
      { property: 'product:retailer_item_id', content: seo.productID },
      { property: 'product:item_group_id', content: seo.name },
      { property: 'product:google_product_category', content: seo.googleProductCategory },
      { property: 'product:category', content: seo.googleProductCategory },
      { property: 'product:fb_product_category', content: seo.facebookProductCategory },
      { property: 'product:size', content: seo.size },
      { property: 'product:age_group', content: seo.ageGroup },
      { property: 'product:gender', content: seo.gender },
      { property: 'product:color', content: seo.color },

    ]);
  }
}
