import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../networking/http.service';
import { Resource } from '../../networking/resource';
import { URLS } from '../../networking/urls';
import { AuthUser } from '../models/core.model';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(private httpService: HttpService) { }

  login(
    loginId: string,
    password: string,
    rememberMe: boolean = false
  ): Observable<Resource<AuthUser>> {
    const body = {
      loginId,
      password,
      rememberMe
    }
    return this.httpService.post<Resource<AuthUser>>(URLS.V1.ACCOUNT.LOGIN, body);
  }

  loginStatus(): Observable<Resource<AuthUser>>{
    return this.httpService.get<Resource<AuthUser>>(URLS.V1.ACCOUNT.LOGIN_STATUS);
  }

  signup(
    firstName: string,
    lastName: string,
    userEmail: string,
    password: string
  ): Observable<Resource<AuthUser>> {
    const body = { firstName, lastName, userEmail, password }
    return this.httpService.post<Resource<AuthUser>>(URLS.V1.ACCOUNT.SIGNUP, body);
  }

  logout(): Observable<Resource<any>> {
    return this.httpService.get<Resource<any>>(URLS.V1.ACCOUNT.LOGOUT);
  }

  sendPassResetLink(
    email: string,
  ): Observable<Resource<any>> {
    const body = { email }
    return this.httpService.post<Resource<any>>(URLS.V1.ACCOUNT.SEND_PASS_RESET_LINK, body);
  }

  resetPassword(
    userEmail: string,
    token: string,
    newPassword: string
  ): Observable<Resource<any>> {
    const body = { userEmail, token, newPassword }
    return this.httpService.post<Resource<any>>(URLS.V1.ACCOUNT.RESET_PASSWORD, body);
  }

  subscribeEmail(
    email: string
  ): Observable<Resource<any>> {
    const body = { email }
    return this.httpService.post<Resource<any>>(URLS.V1.ACCOUNT.SUBSCRIBE, body);
  }

  activateEmail(
    userEmail: string,
    token: string
  ): Observable<Resource<any>> {
    const body = { userEmail, token }
    return this.httpService.post<Resource<any>>(URLS.V1.ACCOUNT.ACTIVATE_EMAIL, body);
  }
}
