<div class="footer-container" *ngIf="!isTablet; else tabletView">
  <div class="left-container">
    <a [routerLink]="['/info/about']" class="footer-link">ABOUT</a>
    <a [routerLink]="['/info']" class="footer-link">CONTACT</a>
    <a [routerLink]="['/info/size-and-care']" class="footer-link">SIZING & CARE</a>
    <a [routerLink]="['/info/terms']" class="footer-link">TERMS</a>
    <a [routerLink]="['/info/privacy-policy']" class="footer-link">PRIVACY POLICY</a>
    <a [routerLink]="['/info/ship-and-returns']" class="footer-link">SHIPPING & RETURNS</a>
  </div>
  <div class="right-container">
    <div class="stay-in-touch">
      LETS STAY IN TOUCH!
    </div>
    <div class="input-container">
      <div class="input-field">
        <input type="email" name="" id="" placeholder="ENTER YOUR EMAIL ADDRESS" class="email-input" [(ngModel)]="email">
        <div class="hit-btn no-selection" (click)="addEmail()">
          HIT
        </div>
      </div>
      <div class="error" *ngIf="error">{{error}}</div>
    </div>
    <div class="footer-logo-section">
      <img src="assets/icons/melt-footer-logo.jpg" alt="" class="footer-logo">
      <div class="copyright-section">
        <img src="assets/icons/copyright.png" alt="" class="copyright-icon">
        <div>{{ currentYear || '2020' }} WE ARE ALL MELT HERE LTD.</div>
      </div>
    </div>
  </div>
</div>
<ng-template #tabletView>
  <div class="footer-container">
    <div class="left-container">
      <div class="left">
        <a [routerLink]="['/info/about']" class="footer-link">ABOUT</a>
        <a [routerLink]="['/info']" class="footer-link">CONTACT</a>
        <a [routerLink]="['/info/size-and-care']" class="footer-link">SIZING & CARE</a>
      </div>
      <div class="right">
        <a [routerLink]="['/info/terms']" class="footer-link">TERMS</a>
        <a [routerLink]="['/info/privacy-policy']" class="footer-link">PRIVACY POLICY</a>
        <a [routerLink]="['/info/ship-and-returns']" class="footer-link">SHIPPING & RETURNS</a>
      </div>
    </div>
    <div class="right-container">
      <div class="stay-in-touch">
        LETS STAY IN TOUCH!
      </div>
      <div class="input-container">
        <div class="input-field">
          <input type="email" name="" id="" placeholder="ENTER YOUR EMAIL ADDRESS" class="email-input" [(ngModel)]="email">
          <div class="hit-btn no-selection" (click)="addEmail()">
            HIT
          </div>
        </div>
        <div class="error" *ngIf="error">{{error}}</div>
      </div>
      <div class="footer-logo-section">
        <img src="assets/icons/melt-footer-logo.jpg" alt="" class="footer-logo">
        <div class="copyright-section">
          <img src="assets/icons/copyright.png" alt="" class="copyright-icon">
          <div>{{ currentYear || '2020' }} WE ARE ALL MELT HERE LTD.</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
