import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../core/services/auth.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  private auth_token: string;

  constructor(
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.auth_token = this.authService.getAuthToken();
    
    if(this.auth_token) {
      const clonedRequest = req.clone({ headers: req.headers.append('Authorization', this.auth_token) });
      return next.handle(clonedRequest);
    }
    return next.handle(req);
  }
}
