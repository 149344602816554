import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HeaderInterceptor } from './networking/header.interceptor';
import { CanLoadGuard } from './core/guards/can-load.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthService } from './core/services/auth.service';

export function initAuthFactory(provider: AuthService) {
  return () => provider.load();
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    AuthService, 
    { provide: APP_INITIALIZER, useFactory: initAuthFactory, deps: [AuthService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    CanLoadGuard,
    AuthGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
