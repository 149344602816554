<div class="header-container no-selection" *ngIf="!isTablet; else showTabletView">
  <div class="left-container">
    <div class="upper-container">
      <div class="jewel-container" (click)="openProducts()" (mouseleave)="showProductsList = false">
        <div class="common-btn" (mouseenter)="showProductsList = true">
          JEWELLERY SHOP
        </div>
        <div class="products-drop-list no-selection" *ngIf="showProductsList">
          <div class="product" *ngFor="let item of CATEGORIES" (click)="openProducts(item); $event.stopPropagation()">
            {{item}}
          </div>
        </div>
      </div>
      <a class="crystal-btn common-btn" [routerLink]="['/crystals']">
        CRYSTALS
      </a>
    </div>
    <a class="lower-container common-btn" [routerLink]="['/info/about']">
      WHO ARE WE ?
    </a>
  </div>
  <a class="middle-container" [routerLink]="['/']">
    <img src="assets/icons/melt-logo.svg" alt="" class="m-auto">
  </a>
  <div class="right-container">
    <div class="upper-container">
      <a class="account-btn common-btn" [routerLink]="['/account']">
        {{ user?.userInfo?.firstName ? user.userInfo.firstName : 'ACCOUNT' }}
      </a>
      <a class="cart-btn common-btn" [routerLink]="['/cart']">
        CART
      </a>
      <div class="cart-count common-btn" [routerLink]="['/cart']">
        {{cartItemCount}}
      </div>
    </div>
    <div class="lower-container">
      <div class="search-btn" (click)="searchQuery()">
        <img src="assets/icons/search.png" alt="" class="whiggle">
      </div>
      <input type="text" name="" id="search-input" placeholder="SEARCH" class="search-input" [(ngModel)]="searchText"  (keyup.enter)="searchQuery()">
    </div>
  </div>
</div>
<ng-template #showTabletView>
  <div class="header-container no-selection">
    <a class="cart-container" [routerLink]="['/cart']">
      <!-- <img src="assets/icons/cart.svg" alt="" class=""> -->
      <div class="cart-items">CART ({{cartItemCount}})</div>
    </a>
    <a class="melt-icon-container" [routerLink]="['/']">
      <img src="assets/icons/melt-logo.svg" alt="" class="w-100-p h-100-p m-auto">
    </a>
    <div class="search-container">
      <img src="assets/icons/search.png" alt="" class="">
    </div>
    <div class="menu-container" (click)="changeSidebar('open')">
      <img src="assets/icons/menu.svg" alt="" class="">
    </div>

    <div class="right-sidebar" *ngIf="showSidebar">
      <div class="sidebar-inner">
        <div class="close-options">
          <div>
            <div class="back" *ngIf="showSidebarJewelOptions" (click)="showSidebarJewelOptions = false">BACK</div>
          </div>
          <img src="assets/icons/cross-white.svg" alt="" (click)="changeSidebar('close')">
        </div>
        <div class="page-options" *ngIf="!showSidebarJewelOptions">
          <div class="product" (click)="showSidebarJewelOptions = true">
            JEWELLERY SHOP
          </div>
          <div class="product" [routerLink]="['/crystals']" (click)="changeSidebar('close')">
            CRYSTALS
          </div>
          <div class="product" [routerLink]="['/account']" (click)="changeSidebar('close')">
            {{ user?.userInfo?.firstName ? user.userInfo.firstName : 'ACCOUNT' }}
          </div>
          <div class="product" [routerLink]="['/info/about']" (click)="changeSidebar('close')">
            WHO ARE WE ?
          </div>
        </div>
        <div class="jewel-options" *ngIf="showSidebarJewelOptions">
          <div class="product" *ngFor="let item of CATEGORIES" (click)="openProducts(item); $event.stopPropagation(); changeSidebar('close')">
            {{item}}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
